import React from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

function AppNavbar() {

    return (
        <Navbar bg="" variant="" expand="sm">
            <Container className="container-small">
                <Nav className="me-auto">

                    <LinkContainer to="/">
                        <Nav.Link>home</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/work">
                        <Nav.Link>portfolio</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/hire-me">
                        <Nav.Link>hire me</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/guestbook">
                        <Nav.Link>guestbook</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/testimonials">
                        <Nav.Link>testimonials</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/blog">
                        <Nav.Link>blog</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/contact">
                        <Nav.Link>contact</Nav.Link>
                    </LinkContainer>

                </Nav>
            </Container>
        </Navbar>
    );

}

export default AppNavbar;
