import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Header from './fragments/Header';
import Footer from './fragments/Footer';

import Home from './pages/Home';
import HireMe from './pages/HireMe';
import Work from './pages/Work';
import Blog from './pages/Blog';
import Post from './pages/Post';
import Guestbook from './pages/Guestbook';
import Testimonials from './pages/Testimonials';
import Error404 from './pages/Error404';
import Contact from './pages/Contact';

import {Toaster} from 'react-hot-toast';

function App() {

    useEffect(() => {
        window.feather.replace();
    }, []);

    return (
        <BrowserRouter>
            <React.Fragment>
                <div><Toaster position={"bottom-center"}/></div>
                <div className={"page-wrapper"}>
                    <Header/>
                    <div className={"content-wrapper"}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/work" element={<Work/>}/>
                            <Route path="/hire-me" element={<HireMe/>}/>
                            <Route path="/guestbook" element={<Guestbook/>}/>
                            <Route path="/testimonials" element={<Testimonials/>}/>
                            <Route path="/blog" element={<Blog/>}/>
                            <Route path="/blog/:slug" element={<Post/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="*" element={<Error404/>}/>
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </React.Fragment>
        </BrowserRouter>
    );

}

export default App;
