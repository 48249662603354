import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default function HireMe() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>{`Hire Me | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content="Hire me for your next project. I am a full-stack developer with experience in PHP, Golang, Node.js, React, and more."/>
            </Helmet>

            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        hire me
                    </h1>

                    <h2>
                        about me
                    </h2>

                    <p>
                        Are you currently looking for a software developer to join your team?
                    </p>

                    <p>
                        Hi, I'm Areeb, a backend developer passionate about building scalable and reliable software solutions. With expertise in PHP and Golang, I specialize in backend development, API integrations, and microservices. I
                        bring extensive experience working with databases like MySQL, Redis, and MongoDB, along with familiarity in frontend technologies such as React.
                    </p>

                    <h2>
                        education
                    </h2>

                    <div className={"d-flex justify-content-lg-between align-items-center"}>
                        <div>
                            <p className={"mb-0"}><strong>Master of Information Technology</strong> | RMIT University, Australia</p>
                        </div>
                        <div>
                            <p className={"mb-0"}>July 2024</p>
                        </div>
                    </div>

                    <div className={"d-flex justify-content-lg-between align-items-center"}>
                        <div>
                            <p className={"mb-0"}><strong>Master of Information Technology</strong> | RMIT University, Australia</p>
                        </div>
                        <div>
                            <p className={"mb-0"}>November 2022</p>
                        </div>
                    </div>

                    <p className={"mt-2"}>
                        Certificate of Commendation (2023); Vice-Chancellor’s List Award (2022)
                        <br/>
                        Certificate of Recognition (2022); IT Prize (2020)
                    </p>

                    <h2>
                        certifications
                    </h2>

                    <div className={"d-flex justify-content-lg-between align-items-center mb-4"}>
                        <div>
                            <p className={"mb-0"}><strong>AWS Cloud Quest: Cloud Practitioner</strong> | Amazon Web Services</p>
                        </div>
                        <div>
                            <p className={"mb-0"}>2023</p>
                        </div>
                    </div>

                    <h2>
                        skills
                    </h2>

                    <p className={"mb-0"}>
                        <b>Programming Languages:</b> Expert in PHP, Golang, HTML/CSS/JS
                    </p>

                    <p className={"mb-0"}>
                        <b>Frameworks & Libraries:</b> Laravel, ReactJS, Next, Bootstrap
                    </p>

                    <p className={"mb-0"}>
                        <b>Databases:</b> MySQL, MongoDB, Redis
                    </p>

                    <p className={"mb-0"}>
                        <b>Queues:</b> RabbitMQ, LavinMQ
                    </p>

                    <p className={"mb-0"}>
                        <b>DevOps & Infrastructure:</b> Terraform, Ansible, Docker, CI/CD
                    </p>

                    <p className={"mb-0"}>
                        <b>Web Servers:</b> Nginx, Apache
                    </p>

                    <p className={"mb-0"}>
                        <b>Version Control & Collaboration:</b> Git
                    </p>

                    <p className={"mb-0"}>
                        <b>Cloud Platforms:</b> AWS, Vultr, DigitalOcean, VMware Cloud Director
                    </p>

                    <p className={"mb-0"}>
                        <b>Development Methodologies:</b> Agile, Scrum
                    </p>

                    <p className={"mb-4"}>
                        <b>Other Technologies:</b> SMTP, SEO, Server Administration, Ubuntu
                    </p>

                    <h2>
                        get in touch
                    </h2>

                    <p>
                        I am currently based in Melbourne, Australia, and I prefer hybrid work arrangements. However, I am also open to remote opportunities across Australia.
                    </p>

                    <p>
                        If you're interested in working together or have any questions, feel free to reach out to me at <a href="mailto:me@areeb.com">me@areeb.com</a>. I look forward to hearing from you!
                    </p>

                </div>
            </Container>
        </HelmetProvider>
    );

}